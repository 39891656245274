import React, { useLayoutEffect, useRef } from 'react'

export function ScrollIntoView() {
  const scrollRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    const delayId = setTimeout(() => {
      scrollRef.current?.scrollIntoView({ block: 'end' })
    }, 600)
    return function () {
      clearTimeout(delayId)
    }
  }, [])
  return (
    <div ref={scrollRef} className="invisible h-px">
      Scroll to div
    </div>
  )
}
