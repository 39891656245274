// Mutate
export const ERRORS_TO_SKIP = [400]

export const DEFAULT_ERROR_MESSAGES = {
  403: 'You are unauthorised to view the resource.',
  504: 'Request timedout. Please try again.',
}
export const DEFAULT_SERVER_ERROR_LABEL = 'Server Error:'
export const DEFAULT_SERVER_ERROR_MESSAGE = 'Something went wrong'

export const CUSTOM_ERROR_LABEL = 'Error:'
export const CUSTOM_SUCCESS_LABEL = 'Success!'

// Scenarios
export const SCENARIO_ARCHIVE_SUCCESS = 'Scenario archived successfully'
export const SCENARIO_UNARCHIVE_SUCCESS = 'Scenario unarchived successfully'
export const SCENARIO_CREATED_SUCCESS = 'Scenario is created successfully'
export const SCENARIO_CREATED_ERROR = 'Failed to created scenario'

export const FOCUSED_PRODUCT_SUCCESS = 'Scenario focus is successfully changed'

// Favourite
export const FAVOURITE_ERROR = 'Failed to process favourite data'

// Fetching
export const DEFAULT_FETCH_ERROR = 'An error occurred while fetching the data.'

// Add Data form
export const EMISSION_FACTOR_FORM_UPDATED = 'Emission factor data was updated'
export const EMISSION_FACTOR_CREATED = 'Emission factor successfully created'
