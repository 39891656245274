import React, { useCallback, useContext, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SearchInput } from '@mondra/ui-components'
import { ICompany } from 'types'
import { AnalysisUrlContext } from 'contexts/AnalysisUrlContextProvider'
import { CompanyContext } from 'contexts/CompanyContextProvider'
import { ROUTE_URLS } from 'constants/routeUrls'
import { COMPANY_ID_SEARCH_PARAM } from 'constants/'
import { SupplierFiltersContext } from 'contexts/SupplierFilterContextProvider'
import { ListSelectDialog } from 'components/ListSelectDialog'

export function CompanySelectDialog() {
  const { companies } = useContext(CompanyContext)
  const { resetFilters } = useContext(SupplierFiltersContext)
  const [, setSearchParams] = useSearchParams()

  const [isOpen, setIsOpen] = useState(false)
  const { navigateToDefaultBrowse } = useContext(AnalysisUrlContext)
  const [filteredCompanies, setFilteredCompanies] = useState(companies)
  const navigate = useNavigate()
  const { company: selectedCompany } = useContext(CompanyContext)

  const handleTriggerButton = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleResetFilters = useCallback(() => {
    resetFilters()
    setFilteredCompanies(companies)
  }, [companies, resetFilters])

  const handleClose = useCallback(() => {
    setIsOpen(false)
    handleResetFilters()
  }, [handleResetFilters])

  const handleSelect = useCallback(
    (company: ICompany) => {
      handleClose()
      if (window.location.pathname.includes(ROUTE_URLS.ANALYSIS)) {
        navigateToDefaultBrowse()
      } else if (window.location.pathname.includes(ROUTE_URLS.SCENARIOS)) {
        navigate(ROUTE_URLS.SCENARIOS)
      }

      setSearchParams({ [COMPANY_ID_SEARCH_PARAM]: company.id })
      handleResetFilters()
    },
    [handleClose, navigate, navigateToDefaultBrowse, handleResetFilters, setSearchParams]
  )

  const handleSearch = (term: string) => {
    const filteredList = companies?.filter(({ name }: ICompany) =>
      (name as string).toLowerCase().includes(term.toLowerCase())
    )
    setFilteredCompanies(term.length ? filteredList : companies)
  }

  return (
    <ListSelectDialog
      items={filteredCompanies}
      selected={selectedCompany}
      open={isOpen}
      onClose={handleClose}
      onOpen={handleTriggerButton}
      onSelect={handleSelect}
      title="Who are you working with?"
      description="Select a company from the list below."
      preListComp={
        <SearchInput
          placeholder="Search by company name"
          className="grid-search mb-2 !rounded"
          inputClassName="grid-search-input"
          onSearch={handleSearch}
        />
      }
    />
  )
}
