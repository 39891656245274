import React, { Dispatch, SetStateAction, createContext, useMemo, useState } from 'react'
import { IChildrenProps } from '@mondra/ui-components/@types'

type NullableNumber = number | null

interface IAgDataContext {
  totalRows: NullableNumber
  setTotalRows: Dispatch<SetStateAction<NullableNumber>>
}

export const AgDataContext = createContext<IAgDataContext>({
  setTotalRows: () => null,
  totalRows: null,
})

export function AgDataProvider({ children }: IChildrenProps) {
  const [totalRows, setTotalRows] = useState<NullableNumber>(null)

  const providerValues = useMemo(
    () => ({
      setTotalRows,
      totalRows,
    }),
    [totalRows]
  )

  return <AgDataContext.Provider value={providerValues}>{children}</AgDataContext.Provider>
}
