import React, { useCallback } from 'react'
import { SkeletonLoader } from 'components/SkeletonLoader'
import Highlighter from 'react-highlight-words'
import { ISearchProductResult } from 'api/types'
import { ProductCategoryFavourite } from 'pages/analysis/product-category-favourite/ProductCategoryFavourite'
import { Dimensions } from 'constants/'

interface ISearchResultItemProps {
  highlighText: string
  product: ISearchProductResult
  onClick: (product: ISearchProductResult) => void
  onFavouriteClick?: (id: string, isFavourite: boolean) => void
}

export function SearchResultItem({
  highlighText,
  product,
  onClick,
  onFavouriteClick,
}: ISearchResultItemProps) {
  const { id, name, sku, isFavourite } = product

  const handleClick = useCallback(() => {
    onClick(product)
  }, [onClick, product])

  return (
    <div
      className="group flex cursor-pointer items-center justify-between rounded bg-coolGray-50 p-2 text-gray-800 hover:bg-primary-500 hover:text-white"
      onClick={handleClick}
      role="presentation"
    >
      <div className="flex items-center">
        <div>
          <Highlighter
            highlightClassName="bg-transparent font-bold group-hover:text-white text-coolGray-900"
            searchWords={[highlighText]}
            textToHighlight={name}
          />
          <div className="flex items-baseline">
            <div className="mr-1 text-xs font-normal">SKU ID:</div>
            <div>
              {sku && (
                <Highlighter
                  highlightClassName="bg-transparent font-bold group-hover:text-white text-coolGray-900"
                  className="text-xs font-normal"
                  searchWords={[highlighText]}
                  textToHighlight={sku}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <ProductCategoryFavourite
          isSet={isFavourite}
          dimension={Dimensions.Product}
          dimensionId={id}
          iconSize={24}
          className="transform-none"
          onChange={onFavouriteClick}
        />
      </div>
    </div>
  )
}

export function SearchResultsLoader() {
  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex h-14 cursor-pointer items-center justify-between rounded bg-gray-50 p-2">
        <div className="flex items-center">
          <div>
            <SkeletonLoader className="h-4 w-40" />
            <SkeletonLoader className="mt-1 h-3 w-64" />
          </div>
        </div>
        <SkeletonLoader className="h-6 w-6" />
      </div>
      <div className="flex h-14 cursor-pointer items-center justify-between rounded bg-gray-50 p-2 opacity-90">
        <div className="flex items-center">
          <div>
            <SkeletonLoader className="h-4 w-40" />
            <SkeletonLoader className="mt-1 h-3 w-64" />
          </div>
        </div>
        <SkeletonLoader className="h-6 w-6" />
      </div>
      <div className="flex h-14 cursor-pointer items-center justify-between rounded bg-gray-50 p-2 opacity-80">
        <div className="flex items-center">
          <div>
            <SkeletonLoader className="h-4 w-40" />
            <SkeletonLoader className="mt-1 h-3 w-64" />
          </div>
        </div>
        <SkeletonLoader className="h-6 w-6" />
      </div>
    </div>
  )
}

export function SearchResultsMoreLoader() {
  return (
    <div className="mt-2 flex w-full flex-col gap-y-2">
      <div className="mx-6 flex h-12 cursor-pointer items-center justify-between rounded-lg bg-gray-100 px-4 py-3">
        <div className="flex items-center">
          <div>
            <SkeletonLoader className="mr-3 h-4 w-4 " />
          </div>
          <div>
            <SkeletonLoader className="h-4 w-40" />
            <SkeletonLoader className="mt-1 h-3 w-64" />
          </div>
        </div>
        <SkeletonLoader className="h-4 w-4" />
      </div>
    </div>
  )
}
