import React, { Suspense, lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import {
  ROUTE_URLS,
  ANALYSIS_SUB_ROUTES,
  SETTINGS_SUB_ROUTES,
  ADD_DATA_SUB_ROUTES,
} from 'constants/routeUrls'
import { COMPANY_FEATURE_FLAGS } from 'constants/featureFlagKeys'
import { useCompanyFeatures } from 'hooks/useCompanyFeatures'
import { SidebarLayoutFallback, DashboardSkeletonLoader } from 'components/SuspenseFallback'
import { ErrorPage } from 'pages/error-page/ErrorPage'
import { PageNotFound } from 'pages/page-not-found/PageNotFound'
import Layout from 'pages/layout/Layout'
import AddDataContextProvider from 'contexts/AddDataContextProvider'
import { AgDataProvider } from './add-data/AgDataContextProvider'
import { AssignProductsProvider } from './add-data/assign-products-wizard/AssignProductsProvider'

const DashboardLayout = lazy(() => import('pages/layout/DashboardLayout'))
const Home = lazy(() => import('pages/home/Home'))
const Analysis = lazy(() => import('pages/analysis/Analysis'))
const AnalysisImpacts = lazy(
  () => import('pages/analysis-dashboards/analysis-impacts/AnalysisImpacts')
)
const AnalysisProducts = lazy(
  () => import('pages/analysis-dashboards/analysis-products/AnalysisProducts')
)
const AnalysisIngredients = lazy(
  () => import('pages/analysis-dashboards/analysis-ingredients/AnalysisIngredients')
)
const AnalysisSuppliers = lazy(
  () => import('pages/analysis-dashboards/analysis-suppliers/AnalysisSuppliers')
)
const AnalysisSupplychain = lazy(
  () => import('pages/analysis-dashboards/analysis-supplychain/AnalysisSupplychain')
)
const AnalysisSourcing = lazy(
  () => import('pages/analysis-dashboards/analysis-sourcing/AnalysisSourcing')
)
const AnalysisScenarios = lazy(
  () => import('pages/analysis-dashboards/analysis-scenarios/AnalysisScenarios')
)
const Scenarios = lazy(() => import('pages/scenarios/Scenarios'))
const ScenariosDashboard = lazy(
  () => import('pages/scenarios/scenarios-dashboard/ScenariosDashboard')
)
const ScenarioDashboard = lazy(() => import('pages/scenarios/scenario-dashboard/ScenarioDashboard'))
const ScenarioProductDashboard = lazy(
  () => import('pages/scenarios/scenario-product/ScenarioProductDashboard')
)
const InviteWizard = lazy(() => import('pages/engagements/invite/InviteWizard'))
const EngagementsDashboard = lazy(() => import('pages/engagements/dashboard/EngagementsDashboard'))

const SettingsGeneral = lazy(() => import('pages/settings/general/SettingsGeneral'))

const AgData = lazy(() => import('pages/add-data/AgData'))
const AgDataWizard = lazy(() => import('pages/add-data/ag-data-wizard/AgDataWizard'))
const AssignProductsWizard = lazy(
  () => import('pages/add-data/assign-products-wizard/AssignProductsWizard')
)

export function AppRoutes() {
  const { hasFeature } = useCompanyFeatures()

  return (
    <Routes>
      <Route path={ROUTE_URLS.BASE} element={<Layout />}>
        <Route index element={<Navigate to={ROUTE_URLS.HOME} />} />
        <Route
          path={ROUTE_URLS.HOME}
          element={
            <Suspense fallback={<DashboardSkeletonLoader />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={ROUTE_URLS.ANALYSIS}
          element={
            <Suspense fallback={<SidebarLayoutFallback />}>
              <Analysis />
            </Suspense>
          }
        >
          <Route
            index
            element={<Navigate to={`${ROUTE_URLS.ANALYSIS}/${ANALYSIS_SUB_ROUTES.HOME}`} />}
          />
          <Route
            path={ANALYSIS_SUB_ROUTES.HOME}
            element={
              <Suspense>
                <AnalysisImpacts />
              </Suspense>
            }
          />
          <Route
            path={ANALYSIS_SUB_ROUTES.PRODUCTS}
            element={
              <Suspense>
                <AnalysisProducts />
              </Suspense>
            }
          />
          <Route
            path={ANALYSIS_SUB_ROUTES.INGREDIENTS}
            element={
              <Suspense>
                <AnalysisIngredients />
              </Suspense>
            }
          />
          <Route
            path={ANALYSIS_SUB_ROUTES.SUPPLIERS}
            element={
              <Suspense>
                <AnalysisSuppliers />
              </Suspense>
            }
          />
          <Route
            path={ANALYSIS_SUB_ROUTES.SCENARIOS}
            element={
              <Suspense>
                <AnalysisScenarios />
              </Suspense>
            }
          />
          <Route
            path={ANALYSIS_SUB_ROUTES.SUPPLY_CHAIN}
            element={
              <Suspense>
                <AnalysisSupplychain />
              </Suspense>
            }
          />
          <Route
            path={ANALYSIS_SUB_ROUTES.SOURCING}
            element={
              <Suspense>
                <AnalysisSourcing />
              </Suspense>
            }
          />
        </Route>
        {hasFeature(COMPANY_FEATURE_FLAGS.SCENARIOS) && (
          <Route
            path={ROUTE_URLS.SCENARIOS}
            element={
              <Suspense fallback={<SidebarLayoutFallback />}>
                <Scenarios />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense>
                  <ScenariosDashboard />
                </Suspense>
              }
            />
            <Route
              path=":scenarioId"
              element={
                <Suspense>
                  <ScenarioDashboard />
                </Suspense>
              }
            />
            <Route
              path=":scenarioId/:productId"
              element={
                <Suspense>
                  <ScenarioProductDashboard />
                </Suspense>
              }
            />
          </Route>
        )}
        {hasFeature(COMPANY_FEATURE_FLAGS.SUPPLIER_INVITATION) && (
          <Route
            path={ROUTE_URLS.ENGAGEMENTS}
            element={
              <Suspense fallback={<DashboardSkeletonLoader />}>
                <DashboardLayout />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense>
                  <EngagementsDashboard />
                </Suspense>
              }
            />
            <Route
              path="invite/*"
              element={
                <Suspense>
                  <InviteWizard />
                </Suspense>
              }
            />
          </Route>
        )}
        {hasFeature(COMPANY_FEATURE_FLAGS.START_DATE_SETTING) && (
          <Route
            path={ROUTE_URLS.SETTINGS}
            element={
              <Suspense fallback={<DashboardSkeletonLoader />}>
                <DashboardLayout />
              </Suspense>
            }
          >
            <Route index element={<Navigate to={SETTINGS_SUB_ROUTES.GENERAL} />} />
            <Route
              path={SETTINGS_SUB_ROUTES.GENERAL}
              element={
                <Suspense>
                  <SettingsGeneral />
                </Suspense>
              }
            />
          </Route>
        )}
        {hasFeature(COMPANY_FEATURE_FLAGS.AG_DATA) && (
          <Route
            path={ROUTE_URLS.ADD_DATA}
            element={
              <Suspense fallback={<DashboardSkeletonLoader />}>
                <DashboardLayout />
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense>
                  <AgDataProvider>
                    <AgData />
                  </AgDataProvider>
                </Suspense>
              }
            />
            <Route
              path={`${ADD_DATA_SUB_ROUTES.AG_DATA}/*`}
              element={
                <Suspense>
                  <AddDataContextProvider>
                    <AgDataWizard />
                  </AddDataContextProvider>
                </Suspense>
              }
            />
            {hasFeature(COMPANY_FEATURE_FLAGS.AG_DATA_ASSIGNMENT) && (
              <Route
                path={`${ADD_DATA_SUB_ROUTES.ASSIGN_PRODUCTS}/*`}
                element={
                  <Suspense>
                    <AssignProductsProvider>
                      <AssignProductsWizard />
                    </AssignProductsProvider>
                  </Suspense>
                }
              />
            )}
          </Route>
        )}
      </Route>
      <Route path={ROUTE_URLS.ERROR} element={<ErrorPage />} />
      <Route path={ROUTE_URLS.NOT_FOUND} element={<PageNotFound />} />
    </Routes>
  )
}
