import React, { useContext } from 'react'
import AnalysisUrlContextProvider from 'contexts/AnalysisUrlContextProvider'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import SupplierFilterContextProvider from 'contexts/SupplierFilterContextProvider'
import { AppRoutes } from 'pages/AppRoutes'
import { AppFallback } from 'components/SuspenseFallback'
import { PendoTracker } from 'components/PendoTracker'
import { ErrorPage } from 'pages/error-page/ErrorPage'
import { CompanyContext } from 'contexts/CompanyContextProvider'
import CompanySyncContextProvider from 'contexts/CompanySyncContextProvider'
import SettingsContextProvider from 'contexts/SettingsContextProvider'
import { useToken } from 'hooks/useToken'
import { useCompanyId } from 'hooks/useCompanyId'
import { PageScrollContextProvider } from 'contexts/PageScrollContextProvider'
import { useToastNotification } from '@mondra/ui-components'
import { TOAST_POSITION } from 'constants/'
import { getAuthLink } from 'utils/graphQLUtils'

export function SecuredApp() {
  const { showError } = useToastNotification(TOAST_POSITION)
  const { isLoading, error } = useContext(CompanyContext)
  const { acquireToken } = useToken()
  const companyId = useCompanyId()

  if (isLoading) {
    return <AppFallback />
  }

  // TODO: Need to use ErrorPage inside the Error boundary and use that for all scenarios
  if (!isLoading && error) {
    return <ErrorPage errorCode={error instanceof Error ? error.message : error} />
  }

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: getAuthLink(acquireToken(companyId), showError),
  })

  return (
    <ApolloProvider client={client}>
      <CompanySyncContextProvider>
        <SettingsContextProvider>
          <SupplierFilterContextProvider>
            <PendoTracker>
              <AnalysisUrlContextProvider>
                <PageScrollContextProvider>
                  <AppRoutes />
                </PageScrollContextProvider>
              </AnalysisUrlContextProvider>
            </PendoTracker>
          </SupplierFilterContextProvider>
        </SettingsContextProvider>
      </CompanySyncContextProvider>
    </ApolloProvider>
  )
}
