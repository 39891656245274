import React from 'react'
import classNames from 'classnames'

interface ISidebarLayoutProps extends React.ComponentPropsWithoutRef<'div'> {
  left?: React.ReactNode
  leftClass?: string
  right: React.ReactNode
  rightClass?: string
}
export function SidebarLayout({
  className,
  left,
  leftClass,
  right,
  rightClass,
}: ISidebarLayoutProps) {
  return (
    <div className={classNames('flex flex-grow flex-row', className)}>
      {left && <div className={classNames('flex-shrink-0', leftClass)}>{left}</div>}
      <div className={classNames('flex flex-grow flex-col', rightClass)}>{right}</div>
    </div>
  )
}
