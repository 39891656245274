import React, { useCallback } from 'react'
import { ConfirmDialog, Tooltip } from '@mondra/ui-components'
import { useControls } from 'hooks/useControls'
import { DarkActionButton } from './DarkActionButton'

interface INewChatProps {
  isCreating: boolean
  onCreate: () => void
}

export function NewChat({ isCreating, onCreate }: INewChatProps) {
  const { open, isOpened, close } = useControls()
  const handleCreate = useCallback(() => {
    close()
    onCreate()
  }, [onCreate, close])
  return (
    <div>
      <Tooltip content="New chat">
        <DarkActionButton iconType="edit" disabled={isCreating} onClick={open} />
      </Tooltip>
      <ConfirmDialog
        variant="warn"
        primaryBtnText="Create"
        hideSecondaryBtn
        open={isOpened}
        onClose={close}
        onConfirm={handleCreate}
        title="Create Chat"
      >
        <div className="text-sm">
          <div>Are you sure you want to create new chat?</div>
          <div>All of your previous messages will be cleared from the context.</div>
        </div>
      </ConfirmDialog>
    </div>
  )
}
