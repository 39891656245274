import { useCallback, useMemo } from 'react'
import useSWRInfinite from 'swr/infinite'
import { API_URLS } from 'constants/apiUrls'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { useCompanyId } from 'hooks/useCompanyId'
import { useToken } from 'hooks/useToken'
import { PAGE_SIZE } from 'constants/'
import { ISearchProductResult, ISearchResponse, SeachTypesEnum } from './types'
import { fetchWithToken } from './fetchWithToken'

interface IUseSearchProps {
  searchText: string
  searchType: SeachTypesEnum
  pageSize?: number
}

function useSearch<T>({ searchText, searchType, pageSize = PAGE_SIZE }: IUseSearchProps) {
  const { acquireToken } = useToken()
  const companyId = useCompanyId()

  const getKey = (pageIndex: number, previousPageData: ISearchResponse<T>) => {
    if (previousPageData && pageIndex === previousPageData.totalPages) {
      return null
    }

    return searchText
      ? resolveSwrUrl(
          API_URLS.SEARCH,
          {
            companyId,
            searchType,
          },
          { filter: searchText, pageNumber: pageIndex + 1, pageSize }
        )
      : null
  }

  const { data, error, isLoading, isValidating, setSize, size } = useSWRInfinite<
    ISearchResponse<T>
  >(getKey, apiUrl => fetchWithToken({ acquireToken, apiUrl, companyId }))

  const loadMore = useCallback(() => {
    setSize(size + 1)
  }, [setSize, size])

  const isLoadingMore = useMemo(() => {
    return isValidating && size > 1 && data && typeof data[size - 1] === 'undefined'
  }, [data, isValidating, size])

  const totalCount = data ? data[0].totalCount : 0
  const hasMore = data ? data[data.length - 1].pageNumber < data[data.length - 1].totalPages : false

  return {
    data: data ? data.map(d => d.data).flat(1) : [],
    error,
    hasMore,
    isLoading,
    isLoadingMore,
    loadMore,
    totalCount,
  }
}

export function useSearchProducts(searchText: string) {
  return useSearch<ISearchProductResult>({ searchText, searchType: SeachTypesEnum.PRODUCTS })
}
