import React from 'react'
import { Header } from '@mondra/ui-components'
import { SidebarLayout } from 'pages/layout/SidebarLayout'
import { BrowseMenu } from 'components/browse-menu/BrowseMenu'
import { Sidebar } from './Sidebar'
import { SkeletonLoader } from './SkeletonLoader'

export function DashboardSkeletonLoader() {
  return (
    <div className="flex flex-grow flex-col">
      <div className="box-border flex flex-shrink-0 animate-fade-in items-center justify-between bg-white px-6 py-2 shadow-border-b-px shadow-gray-300 duration-75">
        <SkeletonLoader className="h-10 w-48" />
      </div>
      <div className="flex flex-grow items-center justify-center">
        <img
          className="mb-[102px] animate-pulse opacity-20 grayscale"
          src="/logo192.png"
          alt="Mondra splash loader"
        />
      </div>
    </div>
  )
}

export function AppFallback() {
  return (
    <div className="flex h-screen w-full flex-col">
      <Header
        className="dark"
        left={
          <div className="px-4">
            <SkeletonLoader className="h-10 w-48" bgColor="bg-coolGray-700" />
          </div>
        }
        right={
          <div className="p-3">
            <SkeletonLoader className="h-8 w-8 rounded-sm" bgColor="bg-coolGray-700" />
          </div>
        }
      >
        <div className="flex w-full items-center justify-end space-x-6 border-l border-gray-700 px-4">
          <SkeletonLoader className="h-10 w-32" bgColor="bg-coolGray-700" />
          <SkeletonLoader className="h-10 w-32" bgColor="bg-coolGray-700" />
          <SkeletonLoader className="h-10 w-32" bgColor="bg-coolGray-700" />
          <SkeletonLoader className="h-10 w-32" bgColor="bg-coolGray-700" />
        </div>
      </Header>
      <SidebarLayout className="flex-1" right={<DashboardSkeletonLoader />} />
    </div>
  )
}

export function SidebarLayoutFallback() {
  return <SidebarLayout left={<Sidebar />} right={<DashboardSkeletonLoader />} />
}

export function LayoutWithOutSidebarFallback() {
  return <SidebarLayout right={<DashboardSkeletonLoader />} />
}

export function AnalysisFallback() {
  return (
    <SidebarLayout
      left={
        <div className="flex-shrink-0">
          <BrowseMenu direction={0} loading header={null} id="suspense-fallback" className="dark">
            <div />
          </BrowseMenu>
        </div>
      }
      right={<DashboardSkeletonLoader />}
    />
  )
}

export function ScenariosFallback() {
  return (
    <div className="flex flex-grow flex-col">
      <div className="box-border flex items-center justify-between bg-white px-6 py-2 shadow-border-b-px shadow-gray-300">
        <h5 className="text-lg font-medium leading-10 text-gray-700">
          <SkeletonLoader className="h-10 w-64" />
        </h5>
      </div>
    </div>
  )
}
