import React, { useMemo } from 'react'
import { Header, NavTabs, Pill } from '@mondra/ui-components'
import { Outlet, useLocation } from 'react-router-dom'
import { ANALYSIS_SUB_ROUTES, ROUTE_URLS } from 'constants/routeUrls'
import { COMPANY_FEATURE_FLAGS, RELEASE_FEATURE_FLAGS } from 'constants/featureFlagKeys'
import { HELP_URLS } from 'constants/helpContent'
import { COMPANY_ID_SEARCH_PARAM, SOURCE_COMPANY_ID_SEARCH_PARAM } from 'constants/'
import { UserAvatar } from 'components/user-avatar/UserAvatar'
import { Search } from 'components/search/Search'
import { CopilotChat } from 'components/copilot-chat/Chat'
import { HelpIcon } from 'components/HelpIcon'
import { useCompanyId } from 'hooks/useCompanyId'
import { useSourceCompanyId } from 'hooks/useSourceCompanyId'
import { useCompanyFeatures } from 'hooks/useCompanyFeatures'
import { resolveUrl } from 'utils/resolveUrl'
import { isFeatureEnabled } from 'utils/isFeatureEnabled'
import { CompanySelectDialog } from './CompanySelectDialog'
import { RetailerSelectDialog } from './RetailerSelectDialog'

const RetailerFilterPaths = [
  ROUTE_URLS.HOME,
  ROUTE_URLS.ANALYSIS,
  ROUTE_URLS.SCENARIOS,
  ROUTE_URLS.ADD_DATA,
]

export default function Layout() {
  const companyId = useCompanyId()
  const { hasFeature } = useCompanyFeatures()

  const { pathname } = useLocation()
  const showRetailerFilter = RetailerFilterPaths.some(p => pathname.indexOf(p) >= 0)

  const sourceCompanyId = useSourceCompanyId()

  const navLinks = useMemo(
    () => [
      {
        label: 'Home',
        to: resolveUrl(
          ROUTE_URLS.HOME,
          {},
          {
            [COMPANY_ID_SEARCH_PARAM]: companyId,
            [SOURCE_COMPANY_ID_SEARCH_PARAM]: sourceCompanyId,
          }
        ),
      },
      {
        label: 'Analysis',
        to: resolveUrl(
          `${ROUTE_URLS.ANALYSIS}/${ANALYSIS_SUB_ROUTES.HOME}`,
          {},
          {
            [COMPANY_ID_SEARCH_PARAM]: companyId,
            [SOURCE_COMPANY_ID_SEARCH_PARAM]: sourceCompanyId,
          }
        ),
      },
      {
        hidden: !hasFeature(COMPANY_FEATURE_FLAGS.SCENARIOS),
        label: 'Scenarios',
        to: resolveUrl(
          ROUTE_URLS.SCENARIOS,
          {},
          {
            [COMPANY_ID_SEARCH_PARAM]: companyId,
            [SOURCE_COMPANY_ID_SEARCH_PARAM]: sourceCompanyId,
          }
        ),
      },
      {
        hidden: !hasFeature(COMPANY_FEATURE_FLAGS.SUPPLIER_INVITATION),
        label: 'Suppliers',
        to: resolveUrl(
          ROUTE_URLS.ENGAGEMENTS,
          {},
          {
            [COMPANY_ID_SEARCH_PARAM]: companyId,
            [SOURCE_COMPANY_ID_SEARCH_PARAM]: sourceCompanyId,
          }
        ),
      },
      {
        hidden: !hasFeature(COMPANY_FEATURE_FLAGS.AG_DATA),
        label: 'Add data',
        to: resolveUrl(
          ROUTE_URLS.ADD_DATA,
          {},
          {
            [COMPANY_ID_SEARCH_PARAM]: companyId,
          }
        ),
      },
    ],
    [companyId, sourceCompanyId, hasFeature]
  )

  return (
    <div className="layout flex min-h-screen flex-col">
      <Header
        className="dark"
        gapClass=""
        left={
          <>
            <CompanySelectDialog />
            {showRetailerFilter && <RetailerSelectDialog />}
          </>
        }
        right={
          <div className="flex h-full items-center justify-between space-x-4">
            <Search />
            <HelpIcon />
            <a
              className="mb-1 inline-block"
              href={HELP_URLS.BETA_RELEASE}
              rel="noreferrer noopener"
              target="_blank"
            >
              <Pill color="green">Beta</Pill>
            </a>
            <UserAvatar />
          </div>
        }
      >
        <div className="w-full border-l border-gray-700">
          <div className="dark flex w-full justify-end space-x-8">
            <NavTabs options={navLinks} gapClass="gap-0" />
          </div>
        </div>
      </Header>
      <div className="flex h-full flex-grow flex-col">
        <Outlet />
        {isFeatureEnabled(RELEASE_FEATURE_FLAGS.MONDRA_COPILOT_ENABLED) &&
          hasFeature(COMPANY_FEATURE_FLAGS.MONDRA_COPILOT) && <CopilotChat />}
      </div>
    </div>
  )
}
