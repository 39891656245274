import { useToastNotification } from '@mondra/ui-components'
import { useCallback } from 'react'
import useSWRMutation from 'swr/mutation'
import { useToken } from 'hooks/useToken'
import { MutateError, TMutateError } from 'api/types'
import {
  DEFAULT_SERVER_ERROR_LABEL,
  DEFAULT_SERVER_ERROR_MESSAGE,
  CUSTOM_ERROR_LABEL,
  CUSTOM_SUCCESS_LABEL,
  ERRORS_TO_SKIP,
  DEFAULT_ERROR_MESSAGES,
} from 'api/constants'
import { MONDRA_HEADERS, TOAST_POSITION } from '../constants'

const enum HttpMethodsEnum {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

interface IFetcher {
  arg: {
    payload: unknown
    token: string | undefined
    method: HttpMethodsEnum
    silenceErrors?: boolean
  }
}

interface IFetcherOptions {
  headers: {
    [key: string]: string
  }
  body?: string
  method: HttpMethodsEnum
}

type TMessage = string | null

async function fetcher(url: string, { arg }: IFetcher) {
  const { payload, token, method, silenceErrors } = arg

  const options: IFetcherOptions = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...MONDRA_HEADERS,
    },
    method,
  }

  if (method !== HttpMethodsEnum.GET) {
    options.body = JSON.stringify(payload)
  }

  const res = await fetch(url, options)

  const text = await res.text()

  let response

  try {
    const data = JSON.parse(text)
    response = data
  } catch (err) {
    response = text
  }

  if (!res.ok && !silenceErrors) {
    throw new MutateError(
      response || {
        detail: DEFAULT_ERROR_MESSAGES[res.status] || DEFAULT_SERVER_ERROR_MESSAGE,
        message: DEFAULT_SERVER_ERROR_LABEL,
        status: res.status,
        traceId: null,
      }
    )
  }

  return response
}

export function useMutate<T = any>(
  url: string | null,
  companyId?: string,
  successMessage: TMessage = null,
  errorMessage: TMessage = null,
  options = {}
) {
  const { acquireToken } = useToken()
  const { showError, showSuccess } = useToastNotification(TOAST_POSITION)

  const defaultOptions = {
    onError: (error: TMutateError) => {
      const { response } = error
      const traceId = response?.traceId ? ` - Trace Id: ${response.traceId}` : ''
      if (errorMessage) {
        showError({
          description: `${errorMessage}${traceId}`,
          label: `${CUSTOM_ERROR_LABEL}-${response.status}`,
        })
      } else if (!ERRORS_TO_SKIP.includes(response.status)) {
        showError({
          description: `${response.detail}${traceId}`,
          label: `${response.message}-${response.status}`,
        })
      }
    },
    onSuccess: () => {
      if (successMessage) {
        showSuccess({
          description: successMessage,
          label: CUSTOM_SUCCESS_LABEL,
        })
      }
    },
  }

  const { trigger: swrTrigger, ...rest } = useSWRMutation(url, fetcher, {
    ...defaultOptions,
    ...options,
  })

  const post = useCallback(
    async (payload = {}, silenceErrors: boolean = false): Promise<T> => {
      const token = await acquireToken(companyId)
      return swrTrigger<T>({ method: HttpMethodsEnum.POST, payload, silenceErrors, token })
    },
    [acquireToken, swrTrigger, companyId]
  )

  const put = useCallback(
    async (payload = {}): Promise<T> => {
      const token = await acquireToken(companyId)
      return swrTrigger<T>({ method: HttpMethodsEnum.PUT, payload, token })
    },
    [acquireToken, swrTrigger, companyId]
  )

  const remove = useCallback(
    async (payload = {}): Promise<T> => {
      const token = await acquireToken(companyId)
      return swrTrigger<T>({ method: HttpMethodsEnum.DELETE, payload, token })
    },
    [acquireToken, swrTrigger, companyId]
  )

  const get = useCallback(
    async (payload = {}): Promise<T> => {
      const token = await acquireToken(companyId)
      return swrTrigger<T>({ method: HttpMethodsEnum.GET, payload, token })
    },
    [acquireToken, swrTrigger, companyId]
  )

  return {
    get,
    post,
    put,
    remove,
    ...rest,
  }
}
